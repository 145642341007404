import { applyMiddleware, legacy_createStore as createStore, type AnyAction, type Store } from 'redux';
import { thunk, type ThunkAction } from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';

import { contactFormApi } from '../../services/contactFormApi';
import { rootReducer } from '../../services/reducers';

// biome-ignore lint/suspicious/noExplicitAny: We don't know the type
export const configureStore = (preloadedState?: any) => {
    const middlewares = [thunk, contactFormApi.middleware];
    const middlewareEnhancer = applyMiddleware(...middlewares);

    const composedMiddleware = composeWithDevTools({})(middlewareEnhancer);

    return createStore(rootReducer, preloadedState, composedMiddleware);
};

export const store: Store = configureStore();

export type RootState = ReturnType<typeof store.getState>;
export type RootDispatch = typeof store.dispatch;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, AnyAction>;
