import qs, { type IStringifyOptions } from 'qs';
import isEmpty from 'lodash/fp/isEmpty';
import isArray from 'lodash/fp/isArray';
import compact from 'lodash/fp/compact';
import isEqual from 'lodash/fp/isEqual';
import isNil from 'lodash/fp/isNil';
import {
    getSortDirShort,
    mapColumnsSettingsToStrings,
    parseColumnsSettingsStrings,
    parseSorting,
} from '@rio-cloud/rio-uikit/routeUtils';

import { tableConfig } from '../features/table/tableConfig';
import { getMapTypeById, getMapTypeId, mapTypeSet, getMapLayersIds, getMapLayersByIds } from '../data/dataDefinition';
import { assetTabs, getAssetTabById, getAssetTabId } from '../features/sidebar/sidebarReducer';
import { defaultMapState } from '../features/map/mapSlice';
import { getBasePath, LIST_PATH, MAP_PATH } from './routes';
import type { CoordinatesShort } from '../services/types';

const QS_OPTIONS: IStringifyOptions = {
    comma: true, // required to parse comma separated string into array
    arrayFormat: 'comma', // required to stringify arrays into comma separated strings
    indices: false, // don't use array indices
    encode: false, // don't encode the entire string as it will be done individually for certain params
    decode: false,
    skipNulls: true, // required to remove empty params
};

export const isMapView = () => isEqual(getBasePath(), MAP_PATH);

const getValueAsArray = (value: string[] | string) => (isArray(value) ? value : [value]);
const getArrayOrNull = <T>(array: T[]) => (isEmpty(compact(array)) ? null : array);
const getAsArrayOrNull = (value: string[] | string | undefined): string[] | null => {
    return !value || isEmpty(value) ? null : getValueAsArray(value);
};

const pathRegExp = new RegExp(`(${LIST_PATH})|(${MAP_PATH})`);

const getAssetId = (pathname = '') => pathname.replace(pathRegExp, '').replace('/', '') || '';

const isValidAssetId = (assetId: string) =>
    assetId ? assetId.match('^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$') : false;

const getValidAssetId = (pathname = '') => {
    const assetId = getAssetId(pathname);
    return isValidAssetId(assetId) ? assetId : undefined;
};

const getActiveAssetIdFragment = (activeAssetId: string) => (activeAssetId ? `/${activeAssetId}` : '');

const sanitizeSearchValue = (value: string | undefined) => (value ? value.replace('%', '%25') : '');

const getValueOrNull = <T>(value: T, defaultValue: T) => (value === defaultValue ? null : value);

const getNumber = (value: unknown) => Number(value);

const getCenter = (lat: string | undefined, lng: string | undefined) => {
    if (lat && lng) {
        return {
            lat: getNumber(lat),
            lng: getNumber(lng),
        };
    }
    return undefined;
};

const getTreeState = (isTreeOpen: boolean, treeCategory: string) =>
    treeCategory ? `${isTreeOpen ? '' : '-'}${treeCategory}` : null;

const parseTreeState = (value: string | undefined) => ({
    category: value ? value.replace('-', '') : 'assets',
    isOpen: value ? !value.startsWith('-') : true,
});

const isBooleanOrNull = (value = '') => {
    const lowerCaseValue = value.toLowerCase();
    if (lowerCaseValue === 'true') {
        return true;
    }
    if (lowerCaseValue === 'false') {
        return false;
    }
    return null;
};

const getTrueOrNull = (value: boolean) => value || null;
const getFalseOrNull = (value: boolean) => !value || null;

const DEFAULT_TAB_ID = '1';
const DEFAULT_MAP_TYPE = '0';
const DEFAULT_TREE_CATEGORY = 'assets';

type ParseRouteProps = {
    location: {
        search?: string;
        pathname?: string;
    };
};

type ParsedSearchParams = Record<string, string | string[] | undefined>;

export const parseRoute = (router: ParseRouteProps | undefined) => {
    const location = router?.location;
    const searchParams = location?.search ? location?.search.replace('?', '') : '';
    const {
        sort,
        tq,
        chunks,
        viewType,
        columns,
        showTableSettings,
        activityFilter,
        assetFilter,
        notificationFilter,
        tabId,
        assetIds,
        groupIds,
        driverIds,
        lock,
        lat,
        lng,
        z,
        tree,
        eg,
        ag,
        dg,
        ft,
        mapType,
        cluster,
        wpois,
        cpois,
        gf,
        ml,
        cs,
        info,
        infoChapter,
        positionSharingDialog,
    } = qs.parse(searchParams, QS_OPTIONS) as ParsedSearchParams;

    const sorting = sort && parseSorting(sort);

    const activeAssetId = getValidAssetId(location?.pathname);

    // Workaround: the qs library parses "%25" automatically to "%" even though we disabled the decoding.
    // Since this would fail when using decodeURIComponent() we need to replace the "%" back to the encoded value.
    const fixedTableSearchValue = sanitizeSearchValue(tq as string);

    const center = getCenter(lat as string, lng as string);

    const isShowCluster = isBooleanOrNull(cluster as string);

    return {
        search: fixedTableSearchValue && decodeURIComponent(fixedTableSearchValue),
        sortBy: sorting?.sortBy,
        sortDir: sorting?.sortDir,
        chunks: chunks && Number.parseInt(chunks as string, 10),
        viewType,
        columns: columns && parseColumnsSettingsStrings(columns as string[], tableConfig.defaultColumnsDetails),
        showTableSettings: isBooleanOrNull(showTableSettings as string),
        activityFilter: getAsArrayOrNull(activityFilter),
        assetFilter: getAsArrayOrNull(assetFilter),
        notificationFilter: getAsArrayOrNull(notificationFilter),
        activeAssetId,
        tabId: getAssetTabById(Number(tabId ?? '1')),
        selectedAssetIds: getAsArrayOrNull(assetIds),
        selectedAssetGroupIds: getAsArrayOrNull(groupIds),
        selectedDriverIds: getAsArrayOrNull(driverIds),
        lockOnAsset: isBooleanOrNull(lock as string),
        center,
        zoom: z ? getNumber(z) : undefined,
        mapType: getMapTypeById(Number(mapType)),
        mapLayers: getMapLayersByIds(getAsArrayOrNull(ml)) ?? [],
        showCluster: isShowCluster === null ? true : isShowCluster,
        showWorkshopPois: isBooleanOrNull(wpois as string),
        showCustomerPois: isBooleanOrNull(cpois as string),
        showGeofences: isBooleanOrNull(gf as string),
        showChargingStations: isBooleanOrNull(cs as string),
        treeCategory: parseTreeState(tree as string).category,
        isTreeOpen: parseTreeState(tree as string).isOpen,
        showEmptyGroups: isBooleanOrNull(eg as string),
        showAssetGroups: isBooleanOrNull(ag as string),
        showDriverGroups: isBooleanOrNull(dg as string),
        showFuelType: isBooleanOrNull(ft as string),
        showServiceInfoDialog: isBooleanOrNull(info as string),
        serviceInfoChapter: infoChapter,
        showShareLinkDialog: isBooleanOrNull(positionSharingDialog as string),
    };
};

export type MakeRouteProp = {
    sortBy: string;
    sortDir: string;
    tableSearch: string;
    chunks: string;
    viewType: string;
    showTableSettings: boolean;
    columns: string;
    activityFilter: string[];
    assetFilter: string[];
    notificationFilter: string[];
    activeAssetId: string;
    selectedAssetIds: string[];
    selectedAssetGroupIds: string[];
    selectedDriverIds: string[];
    tabId: string;
    lockOnAsset: boolean;
    center?: CoordinatesShort;
    zoom: number;
    mapType: string;
    mapLayers: string[];
    showCluster?: boolean;
    showWorkshopPois: boolean;
    showCustomerPois: boolean;
    showGeofences: boolean;
    showChargingStations: boolean;
    treeCategory: string;
    isTreeOpen: boolean;
    areEmptyGroupsShown: boolean;
    areAssetGroupsShown: boolean;
    areDriverGroupsShown: boolean;
    isFuelTypeShown: boolean;
    showServiceInfoDialog: boolean;
    serviceInfoChapter: string;
    showShareLinkDialog: boolean;
    mapRendering?: string;
};

export const makeRoute = ({
    sortBy,
    sortDir,
    tableSearch,
    chunks,
    viewType,
    showTableSettings,
    columns,
    activityFilter,
    assetFilter,
    notificationFilter,
    activeAssetId,
    selectedAssetIds,
    selectedAssetGroupIds,
    selectedDriverIds,
    tabId,
    lockOnAsset,
    center,
    zoom,
    mapType,
    mapLayers,
    showCluster,
    showWorkshopPois,
    showCustomerPois,
    showGeofences,
    showChargingStations,
    treeCategory,
    isTreeOpen,
    areEmptyGroupsShown,
    areAssetGroupsShown,
    areDriverGroupsShown,
    isFuelTypeShown,
    showServiceInfoDialog,
    serviceInfoChapter,
    showShareLinkDialog,
    mapRendering,
}: MakeRouteProp) => {
    const mapTypeId = mapType ? getMapTypeId(mapType, mapTypeSet) : null;
    const assetSidebarTabId = tabId ? getAssetTabId(tabId, assetTabs) : null;
    const currentTreeCategory = getTreeState(isTreeOpen, treeCategory);

    // In case cluster is not defined, it should be omitted from the URL as it is most likely the table view
    const isClusterDefined = !isNil(showCluster);
    const hasCluster = showCluster ? null : isClusterDefined ? false : null;

    // In case groups are not defined, they should be omitted from the URL as it is most likely the table view
    const showAssetGroups = isNil(areAssetGroupsShown) ? null : getFalseOrNull(areAssetGroupsShown);
    const showDriverGroups = isNil(areDriverGroupsShown) ? null : getFalseOrNull(areDriverGroupsShown);

    // Set props to undefined or null in order to remove it from the URL when not defined
    const params = {
        tq: tableSearch ? encodeURIComponent(tableSearch) : null,
        sort: sortBy && `${getSortDirShort(sortDir)}${sortBy}`,
        chunks, //: getValueOrNull(chunks, defaultTableState.chunks),
        viewType, //: getValueOrNull(viewType, defaultTableState.viewType),
        assetIds: getArrayOrNull(selectedAssetIds),
        groupIds: getArrayOrNull(selectedAssetGroupIds),
        driverIds: getArrayOrNull(selectedDriverIds),
        columns: columns && mapColumnsSettingsToStrings(columns),
        showTableSettings: getTrueOrNull(showTableSettings),
        activityFilter: getArrayOrNull(activityFilter),
        assetFilter: getArrayOrNull(assetFilter),
        notificationFilter: getArrayOrNull(notificationFilter),
        // If sidebar tabId Id is the first tab, we hide it from the URL as it is the default
        tabId: assetSidebarTabId === DEFAULT_TAB_ID ? null : assetSidebarTabId,
        lock: getValueOrNull(lockOnAsset, defaultMapState.lockOnAsset),
        lat: center?.lat, //getOr(null, 'lat')(center),
        lng: center?.lng, //getOr(null, 'lng')(center),
        z: zoom,
        // If mapType Id is the default map type, we hide it from the URL as it is the default
        mapType: mapTypeId === DEFAULT_MAP_TYPE ? null : mapTypeId,
        ml: isEmpty(mapLayers) ? null : getMapLayersIds(mapLayers),
        // If clusters are disabled we add it to the URL as "false", otherwise we hide it as it is the default
        cluster: hasCluster,
        wpois: getTrueOrNull(showWorkshopPois),
        cpois: getTrueOrNull(showCustomerPois),
        gf: getTrueOrNull(showGeofences),
        cs: getTrueOrNull(showChargingStations),
        // If tree is on the assets category, we hide it from the URL as it is the default
        tree: currentTreeCategory === DEFAULT_TREE_CATEGORY ? null : currentTreeCategory,
        eg: getTrueOrNull(areEmptyGroupsShown),
        ft: getTrueOrNull(isFuelTypeShown),
        ag: showAssetGroups,
        dg: showDriverGroups,
        info: getTrueOrNull(showServiceInfoDialog),
        infoChapter: showServiceInfoDialog ? serviceInfoChapter : null,
        positionSharingDialog: getTrueOrNull(showShareLinkDialog),
    };

    const queryParams = qs.stringify(params, QS_OPTIONS);
    const searchFragment = queryParams && `?${queryParams}`;

    return `${getBasePath()}${getActiveAssetIdFragment(activeAssetId)}${searchFragment}`;
};
