import { createSlice, type PayloadAction } from '@reduxjs/toolkit';

import { actions as appActions } from '../../services/actions';
import isNil from 'lodash/fp/isNil';

// Note: these IDs are also used as tree category for the URL, hence keep it short
export const TREE_CATEGORY_SEARCH = 'search';
export const TREE_CATEGORY_FILTER = 'filter';
export const TREE_CATEGORY_ROUTE = 'route';
export const TREE_CATEGORY_SMART_ROUTE = 'smart_route';
export const TREE_CATEGORY_CHARGING_STATIONS = 'charging';
export const TREE_CATEGORY_SIMPLE_PAY = 'simplePay';
export const TREE_CATEGORY_ASSETS = 'assets';
export const TREE_CATEGORY_DRIVER = 'driver';
export const TREE_CATEGORY_POIS = 'pois';
export const TREE_CATEGORY_GEOFENCES = 'geofences';

export type TREE_CATEGORY =
    | 'search'
    | 'filter'
    | 'route'
    | 'smart_route'
    | 'assets'
    | 'driver'
    | 'pois'
    | 'geofences'
    | 'charging'
    | 'simplePay';

export const TREE_ASSET_TYPES = ['truck', 'bus', 'van', 'trailer'];
export const TREE_FUEL_TYPES = ['fuel-diesel', 'fuel-electric', 'fuel-gas', 'fuel-hydrogen'];

export type TreeState = {
    selectedDriverIds: string[];
    selectedAssetGroupIds: string[];
    selectedAssetIds: string[];
    expandedAssetGroups: string[];
    expandedDriverGroups: string[];
    treeCategory: TREE_CATEGORY;
    isTreeOpen: boolean;
    globalSearchValue: string;
    showEmptyGroups: boolean;
    showAssetGroups: boolean;
    showDriverGroups: boolean;
    showFuelType?: boolean;
};

export const defaultTreeState: TreeState = {
    selectedDriverIds: [],
    selectedAssetGroupIds: [],
    selectedAssetIds: [],
    expandedAssetGroups: [],
    expandedDriverGroups: [],
    treeCategory: TREE_CATEGORY_ASSETS,
    isTreeOpen: true,
    globalSearchValue: '',
    showEmptyGroups: false,
    showAssetGroups: true,
    showDriverGroups: true,
    showFuelType: undefined,
};

export const treeSlice = createSlice({
    name: 'tree',
    initialState: defaultTreeState,
    reducers: {
        emptyGroupsToggled: (state: TreeState) => ({ ...state, showEmptyGroups: !state.showEmptyGroups }),
        assetGroupsToggled: (state: TreeState) => ({ ...state, showAssetGroups: !state.showAssetGroups }),
        driverGroupsToggled: (state: TreeState) => ({ ...state, showDriverGroups: !state.showDriverGroups }),
        fuelTypeToggled: (state: TreeState) => ({
            ...state,
            showFuelType: !isNil(state.showFuelType) && !state.showFuelType,
        }),

        // handled by the mapSlice
        // searchResultSelected: (state: TreeState, action: PayloadAction<unknown>) => ({
        //     ...state,
        // }),
    },
    extraReducers: builder => {
        builder.addCase(appActions.expandedAssetGroupsChanged, (state: TreeState, action: PayloadAction<unknown>) => {
            state.expandedAssetGroups = action.payload as string[];
        });
        builder.addCase(appActions.expandedDriverGroupsChanged, (state: TreeState, action: PayloadAction<unknown>) => {
            state.expandedDriverGroups = action.payload as string[];
        });
        builder.addCase(appActions.selectedAssetsChanged, (state: TreeState, action: PayloadAction<unknown>) => {
            state.selectedAssetIds = (action.payload as string[]) || defaultTreeState.selectedAssetIds;
        });
        builder.addCase(appActions.selectedAssetGroupsChanged, (state: TreeState, action: PayloadAction<unknown>) => {
            state.selectedAssetGroupIds = (action.payload as string[]) || defaultTreeState.selectedAssetGroupIds;
        });
        builder.addCase(appActions.selectedDriversChanged, (state: TreeState, action: PayloadAction<unknown>) => {
            state.selectedDriverIds = action.payload as string[];
        });
        builder.addCase(appActions.treeToggled, (state: TreeState) => {
            state.isTreeOpen = !state.isTreeOpen;
        });
        builder.addCase(appActions.globalSearchValueChanged, (state: TreeState, action: PayloadAction<unknown>) => {
            state.globalSearchValue = action.payload as string;
        });
        builder.addCase(appActions.treeCategoryChanged, (state: TreeState, action: PayloadAction<unknown>) => {
            if (state.treeCategory !== action.payload) {
                state.treeCategory = action.payload as TREE_CATEGORY;

                const isSingleAssetSelected = state.selectedAssetIds.length === 1;
                const hasNoGroupsSelected = state.selectedAssetGroupIds.length === 0;

                // Reset active asset selection on tree category change to not limit the search to a single asset only
                if (isSingleAssetSelected && hasNoGroupsSelected) {
                    // state.selectedDriverIds = defaultTreeState.selectedDriverIds;
                    // state.selectedAssetGroupIds = defaultTreeState.selectedAssetGroupIds;
                    state.selectedAssetIds = defaultTreeState.selectedAssetIds;
                }
            }
        });
    },
});

export const {
    /*searchResultSelected,*/ emptyGroupsToggled,
    assetGroupsToggled,
    driverGroupsToggled,
    fuelTypeToggled,
} = treeSlice.actions;
export { appActions };

export default treeSlice.reducer;
