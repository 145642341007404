import { createSelector } from '@reduxjs/toolkit';
import get from 'lodash/fp/get';
import isEmpty from 'lodash/fp/isEmpty';
import compact from 'lodash/fp/compact';
import isNil from 'lodash/fp/isNil';

import {
    getCustomerPois,
    getData,
    getDrivers,
    getGroups,
    getSelectedAssetGroupIds,
    getSelectedAssetIds,
    getWorkshopPois,
} from '../app/selectors';
import { getActivityFilter } from '../table/tableSelectors';
import { type MapGeofence, getFilteredMapAssetData, getMapGeofences, hasActiveMapFilter } from '../map/mapSelectors';
import { UNGROUPED_ITEMS_GROUP_ID } from '../../services/fetchGroups';
import type { CombinedAssetData, Driver, FuelType, GeoBookingState, Poi } from '../../services/types';
import type { RootState } from '../../configuration/setup/store';
import type { TREE_CATEGORY } from './treeSlice';
import { featureToggles } from '../../configuration/setup/featureToggles';

type TreeDriver = {
    id: string;
    name: {
        firstName: string;
        lastName: string;
    };
    type: string;
    groupIds: string[];
};

type TreeAsset = {
    id: string;
    name: string;
    type: string;
    subType?: string;
    groupIds: string[];
    hasPosition: boolean;
    geoBookingState: GeoBookingState;
    smartRoutePlanningBooked: boolean;
};

export const getExpandedAssetGroups = (state: RootState): string[] => state.app.tree.expandedAssetGroups;
export const getExpandedDriverGroups = (state: RootState): string[] => state.app.tree.expandedDriverGroups;
export const getTreeCategory = (state: RootState): TREE_CATEGORY => state.app.tree.treeCategory;
export const isTreeOpen = (state: RootState): boolean => state.app.tree.isTreeOpen;
export const showEmptyGroups = (state: RootState): boolean => state.app.tree.showEmptyGroups;
export const showFuelType = (state: RootState): boolean => state.app.tree.showFuelType;
export const showAssetGroups = (state: RootState): boolean => state.app.tree.showAssetGroups;
export const showDriverGroups = (state: RootState): boolean => state.app.tree.showDriverGroups;

export const hasActiveAssetFilter = createSelector(
    getSelectedAssetGroupIds,
    getSelectedAssetIds,
    getActivityFilter,
    (selectedGroups, selectedAssets, activityFilter) =>
        !isEmpty(selectedGroups) || !isEmpty(selectedAssets) || !isEmpty(activityFilter)
);

export const getGlobalSearchValue = (state: RootState): string => state.app.tree.globalSearchValue;

const getDriverFullName = (driver: TreeDriver) => `${driver.name.firstName} ${driver.name.lastName}`;
const getGroupIds = (groupIds: string[] | undefined): string[] =>
    groupIds === undefined || isEmpty(groupIds) ? [UNGROUPED_ITEMS_GROUP_ID] : groupIds;

const transformFuelType = (assetFuelType?: FuelType, showFuelType?: boolean): string | undefined => {
    if (!showFuelType) {
        return;
    }
    return assetFuelType ? `fuel-${assetFuelType}` : undefined;
};

const transformAssetForTree = (rawAsset: CombinedAssetData, showFuelType: boolean): TreeAsset => ({
    id: rawAsset.vehicleId,
    name: rawAsset.name,
    type: rawAsset.type,
    subType: transformFuelType(rawAsset.fuelType, showFuelType),
    groupIds: getGroupIds(rawAsset.groupIds),
    hasPosition: !isNil(rawAsset.longitude) && !isNil(rawAsset.latitude),
    geoBookingState: rawAsset.geoBookingState,
    smartRoutePlanningBooked: rawAsset.smartRoutePlanningBooked,
});

export const transformDriverForTree = (driver: Driver): TreeDriver => ({
    id: driver.driverId,
    name: {
        firstName: driver.firstName,
        lastName: driver.lastName,
    },
    type: 'driver',
    groupIds: getGroupIds(driver.groupIds),
});

export const getTreeAssets = createSelector([getData, showFuelType], (rawData, showFT) =>
    rawData?.map(asset => transformAssetForTree(asset, showFT))
);

export const getTreeDrivers = createSelector(getDrivers, getData, (drivers, rawData) => {
    if (!drivers || !rawData) {
        return [];
    }

    const driversOnAssets = rawData.filter(get('driverId')).map(get('driverId'));

    const filteredDrivers = drivers.map(driver => {
        if (!driversOnAssets.includes(driver.driverId)) {
            return false;
        }
        return transformDriverForTree(driver);
    });
    return compact(filteredDrivers);
});

export const getTreeGroups = createSelector(getGroups, groups =>
    groups?.map(group => {
        return {
            id: group.id,
            name: group.name,
            position: group.position,
        };
    })
);

export const getFilteredAssetsByName = createSelector(
    getFilteredMapAssetData,
    getGlobalSearchValue,
    (assets, globalSearchValue) => {
        return assets && !isEmpty(globalSearchValue)
            ? assets.filter(asset => asset.name.toLowerCase().includes(globalSearchValue.toLowerCase()))
            : [];
    }
);

const filterPoiByNameAndAddress = (item: Poi, searchValue: string) => {
    const { name = '', address = '' } = item;
    return (
        name.toLowerCase().includes(searchValue.toLowerCase()) ||
        address.toLowerCase().includes(searchValue.toLowerCase())
    );
};

const filterGeofenceByName = (item: MapGeofence, searchValue: string) => {
    const { name = '' } = item;
    return name.toLowerCase().includes(searchValue.toLowerCase());
};

const filterPois = (pois: Poi[], searchValue: string) => {
    return pois && !isEmpty(searchValue) ? pois.filter(poi => filterPoiByNameAndAddress(poi, searchValue)) : [];
};

export const getFilteredCustomerPois = createSelector(
    getCustomerPois,
    getGlobalSearchValue,
    (customerPois, globalSearchValue) => {
        return filterPois(customerPois, globalSearchValue);
    }
);

export const getFilteredWorkshopPois = createSelector(
    getWorkshopPois,
    getGlobalSearchValue,
    (workshopPois, globalSearchValue) => {
        return filterPois(workshopPois, globalSearchValue);
    }
);

const filterGeofences = (geofences: MapGeofence[], searchValue: string) =>
    geofences && !isEmpty(searchValue) ? geofences.filter(geofence => filterGeofenceByName(geofence, searchValue)) : [];

export const getFilteredGeofences = createSelector(
    getMapGeofences,
    getGlobalSearchValue,
    (geofences, globalSearchValue) => {
        return filterGeofences(geofences, globalSearchValue);
    }
);

const filterByName = (driver: TreeDriver, searchValue = '') =>
    getDriverFullName(driver).toLowerCase().includes(searchValue.toLowerCase());

const filterDriversNameByValue = (drivers: TreeDriver[] = [], searchValue = '') =>
    drivers.filter(driver => filterByName(driver, searchValue));

export const getFilteredDriversByName = createSelector(
    getTreeDrivers,
    getGlobalSearchValue,
    getFilteredMapAssetData,
    hasActiveMapFilter,
    (drivers, globalSearchValue, filteredMapAssets, hasActiveFilter) => {
        if (isEmpty(globalSearchValue) || !drivers) {
            return [];
        }

        // When there are no active filter, means assets, groups or other tree filter,
        // then take the entire list of drivers into account
        if (!hasActiveFilter) {
            return filterDriversNameByValue(drivers, globalSearchValue);
        }

        // In case there are active map filter, limit the driver search to the list of the assets
        const filteredDriverIds = filteredMapAssets.map(asset => asset.driverId);

        return drivers.filter(
            driver => filteredDriverIds.includes(driver.id) && filterByName(driver, globalSearchValue)
        );
    }
);
