import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import head from 'lodash/fp/head';
import debounce from 'lodash/fp/debounce';
import isEmpty from 'lodash/fp/isEmpty';

import { isMapView, makeRoute } from './routeIntents';
import {
    getActiveAssetId,
    getSelectedAssetGroupIds,
    getSelectedAssetIds,
    getSelectedDriverIds,
} from '../features/app/selectors';
import {
    getCenter,
    getLockOnAsset,
    getMapType,
    getMapLayers,
    getShowCluster,
    getShowWorkshopPois,
    getShowCustomerPois,
    getShowGeofences,
    getZoom,
    getMapRendering,
    getShowChargingStations,
} from '../features/map/mapSelectors';
import {
    getTreeCategory,
    isTreeOpen,
    showEmptyGroups,
    showAssetGroups,
    showDriverGroups,
    showFuelType,
} from '../features/tree/treeSelectors';
import {
    getActivityFilter,
    getAssetFilter,
    getChunkCounter,
    getColumns,
    getNotificationFilter,
    getSearchValue,
    getSortBy,
    getSortDir,
    getViewType,
    showTableSettingsDialog,
} from '../features/table/tableSelectors';
import { getSidebarTabId } from '../features/sidebar/sidebarSelectors';
import { isServiceInfoDialogShown, getServiceInfoDialogChapter } from '../features/serviceInfo/serviceInfoSelectors';
import { isShareLinkDialogShown } from '../features/dialogs/shareLinkDialog/shareLinkSlice';
import { rawDataLayerPush } from '../configuration/setup/googleTagManager';
import { getBasePath } from '../routes/routes';
import { usePreviousLocation } from '../hooks/usePreviousLocation';

const PUSH_ROUTE_DEBOUNCE = 200;

const getCommonRouteProps = state => {
    return {
        activeAssetId: getActiveAssetId(state),
        tabId: getSidebarTabId(state),
        showServiceInfoDialog: isServiceInfoDialogShown(state),
        serviceInfoChapter: getServiceInfoDialogChapter(state),
        showShareLinkDialog: isShareLinkDialogShown(state),
    };
};

const getMapViewRouteProps = state => {
    return {
        ...getCommonRouteProps(state),
        selectedAssetIds: getSelectedAssetIds(state),
        selectedAssetGroupIds: getSelectedAssetGroupIds(state),
        selectedDriverIds: getSelectedDriverIds(state),
        treeCategory: getTreeCategory(state),
        isTreeOpen: isTreeOpen(state),
        areEmptyGroupsShown: showEmptyGroups(state),
        areAssetGroupsShown: showAssetGroups(state),
        areDriverGroupsShown: showDriverGroups(state),
        isFuelTypeShown: showFuelType(state),
        center: getCenter(state),
        zoom: getZoom(state),
        mapType: getMapType(state),
        mapLayers: getMapLayers(state),
        showCluster: getShowCluster(state),
        showWorkshopPois: getShowWorkshopPois(state),
        showCustomerPois: getShowCustomerPois(state),
        showGeofences: getShowGeofences(state),
        showChargingStations: getShowChargingStations(state),
        lockOnAsset: getLockOnAsset(state),
        mapRendering: getMapRendering(state),
    };
};

const getTableViewRouteProps = state => {
    return {
        ...getCommonRouteProps(state),
        tableSearch: getSearchValue(state),
        sortBy: getSortBy(state),
        sortDir: getSortDir(state),
        columns: getColumns(state),
        activityFilter: getActivityFilter(state),
        assetFilter: getAssetFilter(state),
        notificationFilter: getNotificationFilter(state),
        chunks: getChunkCounter(state),
        viewType: getViewType(state),
        showTableSettings: showTableSettingsDialog(state),
    };
};

// Check if location ist list or map and return path and search queries accordingly. Note, this could
// lead to a redirect if check fails.
const getRouteFittingToState = state => {
    const routeProps = isMapView() ? getMapViewRouteProps(state) : getTableViewRouteProps(state);
    return makeRoute(routeProps);
};

export const getFirstLevelOfUrl = path => head(path.match(/\/\w*/));

export const RouterUpdater = () => {
    const [lastPath, setLastPath] = useState(getBasePath());

    const navigate = useNavigate();
    const location = useLocation();
    const prevLocation = usePreviousLocation(location);

    const routeToPushTo = useSelector(getRouteFittingToState);

    const pushRoute = debounce(PUSH_ROUTE_DEBOUNCE)(route => navigate(route));

    const handleRouteUpdates = () => {
        const currentRoute = `${location.pathname}${location.search}`;
        const newBasePath = getFirstLevelOfUrl(routeToPushTo);

        if (!prevLocation && !isEmpty(location.search)) {
            // On first load, the route update should do nothing as the state will be restored
            // by the history listener and we don't want tu update the URL again to avoid an endless loop
            return;
        }

        if (routeToPushTo !== currentRoute) {
            if (lastPath !== newBasePath) {
                // Change main view to map or list
                navigate(routeToPushTo, { replace: true });
                setLastPath(newBasePath);
                rawDataLayerPush({
                    event: 'virtPath',
                    virtPath: newBasePath,
                });
            } else {
                pushRoute(routeToPushTo);
            }
        }
    };

    useEffect(() => {
        handleRouteUpdates();
    }, [routeToPushTo]);

    return null;
};
