import qs from 'qs';

import { storage } from './storage';
import { useUserAccount } from '../login/loginSlice';

const PREFIX = 'featureToggle.';

const detectFeatureToggles = () => {
    // cleanup old toggles
    try {
        storage.removeItem('ft_eventTeaser', PREFIX);
        storage.removeItem('ft_survey', PREFIX);
        storage.removeItem('ft_menu', PREFIX);
        storage.removeItem('ft_nolt', PREFIX);
        storage.removeItem('ft_geo', PREFIX);
        storage.removeItem('ft_chat', PREFIX);
    } catch (error) {
        console.error(error);
    }

    // biome-ignore lint/suspicious/noExplicitAny: We don't know the type
    const detectToggle = (transform: (value: any) => any, name: string) => {
        const [_, searchParams = ''] = window.location.hash.split('?');
        const params = qs.parse(searchParams);
        const param = params[name] ? params[name] : undefined;
        const toggleValue = param ? param.toString() : undefined;
        const value = toggleValue ? storage.save(name, toggleValue, PREFIX) : storage.load(name, PREFIX);

        return value && transform(value);
    };

    const BooleanToggle = (value: string): boolean => {
        return value === 'true' || value === '1';
    };

    return {
        enforcedEnv: detectToggle(String, 'ft_env'),
        enforcedLocale: detectToggle(String, 'ft_enforceLocale'),
        enforcedLocalWidget: detectToggle(String, 'ft_localWidget'),
        tracing: detectToggle(BooleanToggle, 'ft_tracing'),
        support: detectToggle(BooleanToggle, 'ft_support'),
        simulateGeoBooked: detectToggle(String, 'ft_simulateGeoBooked'),
        smartRoutePlanning: detectToggle(BooleanToggle, 'ft_smartRoutePlanning'),
        smartRoutePlanningBooking: detectToggle(BooleanToggle, 'ft_srpBooking'),
        deliveryStatus: detectToggle(BooleanToggle, 'ft_deliveryStatus'),
        demo: detectToggle(BooleanToggle, 'ft_demo'),
        simplePay: detectToggle(BooleanToggle, 'ft_simplePay'),
        skipChristmas: detectToggle(BooleanToggle, 'skipChristmas'),
    };
};

export const featureToggles = detectFeatureToggles();

export const useWhiteListedFeatureToggles = (whiteList: string[] = []) => {
    const accountId = useUserAccount();
    return whiteList.includes(accountId);
};

export const useSmartRouteFeatureToggle = () => {
    const smartRouteWhiteList = [
        'mockaccount',
        '8ef04c71-8105-4bfb-a97c-a70db9d66975', // "RIO VIT"
        '237c58e3-60b5-4442-a9ec-8641ea39325b', // "Lastkraftwagenbauer"
        '284b3c1f-72c1-4669-a51b-c7ca9cc3c967', // “Demofleet MAN Sales”
        'ac464fce-f700-49a7-aa13-60b0c5c3f9ce', // “Testaccount MAN Truck & Bus SE”
        '0eaa962f-ddf9-4280-9d37-3d0760959493', // "EEV-Trucktesting"
        'be0fa59d-6b42-4025-9039-1c1ac8efb4e1', // MAN Truck and Bus SE
        '0ff74f0d-c891-47ca-a7e6-9501be388fe7', // LoadFox
    ];
    return useWhiteListedFeatureToggles(smartRouteWhiteList);
};
