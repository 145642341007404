import { createAction } from '@reduxjs/toolkit';

import type {
    EventCenterMap,
    EventChargingStationSelected,
    EventEditCustomerPoi,
    EventEditGeofence,
    EventMapChanged,
    EventMarkerSelected,
    EventRenderChargingStations,
    EventRenderMarkers,
    EventRenderRoute,
    EventRenderShape,
    EventSelectAsset,
    EventSelectGeofences,
    EventSelectPois,
    EventSetBoundingBox,
    EventSetRoutingEndPoint,
    EventSetRoutingStopoverPoint,
    EventSetRoutingStartPoint,
    EventSetZoom,
    EventSendToWidgets,
    EventAssetTreeCategory,
    EventAssetSidebarTab,
    EventActiveAdditionalLayerChanged,
} from './remoteActionTypes';

export const widgetLoadedType = 'EVENT_WIDGET_LOADED';
export const setMapContextType = 'EVENT_SET_MAP_CONTEXT';
export const centerMapType = 'EVENT_CENTER_MAP';
export const setZoomType = 'EVENT_SET_ZOOM';
export const setBoundingboxType = 'EVENT_SET_BOUNDING_BOX';
export const centerActiveAssetType = 'EVENT_CENTER_ACTIVE_ASSET';
export const editCustomerPoiType = 'EVENT_EDIT_CUSTOMER_POI';
export const editGeofenceType = 'EVENT_EDIT_GEOFENCE';
export const refreshPoisType = 'EVENT_REFRESH_POIS';
export const refreshGeofencesType = 'EVENT_REFRESH_GEOFENCES';
export const chargingStationsSelectedType = 'EVENT_MAP_CHARGING_STATIONS_SELECTED';
export const renderChargingStationType = 'EVENT_RENDER_CHARGING_STATIONS';
export const forwardToWidgetsType = 'EVENT_SEND_TO_WIDGETS';
export const treeCategoryChangedType = 'EVENT_SET_ASSET_TREE_CATEGORY';
export const sidebarTabSelectionChangedType = 'EVENT_SET_ASSET_SIDEBAR_TAB';
export const toggleLayerChargingStationsType = 'EVENT_TOGGLE_LAYER_CHARGING_STATIONS';
export const chatTotalUnreadMessageCountChangedType = 'EVENT_WIDGET_CHAT_TOTAL_UNREAD_MESSAGE_COUNT_CHANGED';

// Note: these actions need to keep this name as it is used by the widgets like that
export const remoteActions = {
    widgetLoaded: createAction(widgetLoadedType),
    setWidgetId: createAction<string | undefined>('EVENT_SET_WIDGET_ID'),
    setVisibleWidgets: createAction<string[]>('EVENT_SET_VISIBLE_WIDGETS'),
    setMapContext: createAction<string>(setMapContextType),
    selectAsset: createAction<EventSelectAsset>('EVENT_SELECT_ASSET'),
    selectPois: createAction<EventSelectPois>('EVENT_SELECT_POIS'),
    selectGeofences: createAction<EventSelectGeofences>('EVENT_SELECT_GEOFENCES'),
    setRoutingStartPoint: createAction<EventSetRoutingStartPoint>('EVENT_SET_ROUTING_START_POINT'),
    setRoutingStopoverPoint: createAction<EventSetRoutingStopoverPoint>('EVENT_SET_ROUTING_STOPOVER_POINT'),
    setRoutingEndPoint: createAction<EventSetRoutingEndPoint>('EVENT_SET_ROUTING_END_POINT'),
    centerMap: createAction<EventCenterMap>(centerMapType),
    setBoundingBox: createAction<EventSetBoundingBox>(setBoundingboxType),
    setZoom: createAction<EventSetZoom>(setZoomType),
    toggleLayerChargingStations: createAction<boolean>(toggleLayerChargingStationsType),
    treeCategoryChanged: createAction<EventAssetTreeCategory>(treeCategoryChangedType),
    sidebarTabSelectionChanged: createAction<EventAssetSidebarTab>(sidebarTabSelectionChangedType),
    forwardToWidgets: createAction<EventSendToWidgets>(forwardToWidgetsType),
    changeLocale: createAction<string>('lang/CHANGE_LOCALE'),
    renderRoute: createAction<EventRenderRoute>('EVENT_RENDER_ROUTE'),
    renderShape: createAction<EventRenderShape>('EVENT_RENDER_SHAPE'),
    renderChargingStations: createAction<EventRenderChargingStations>(renderChargingStationType),
    chargingStationsSelected: createAction<EventChargingStationSelected>(chargingStationsSelectedType),
    mapMarkerSelected: createAction<EventMarkerSelected>('EVENT_MAP_MARKER_SELECTED'),
    mapChanged: createAction<EventMapChanged>('EVENT_MAP_CHANGED'),
    renderMapMarkers: createAction<EventRenderMarkers>('EVENT_RENDER_MAP_MARKERS'),
    zoomToMapElements: createAction('EVENT_ZOOM_TO_MAP_ELEMENTS'),
    centerActiveAsset: createAction(centerActiveAssetType),
    editCustomerPoi: createAction<EventEditCustomerPoi>(editCustomerPoiType),
    editGeofence: createAction<EventEditGeofence>(editGeofenceType),
    refreshPois: createAction(refreshPoisType),
    refreshGeofences: createAction(refreshGeofencesType),
    activeAdditionalLayerChanged: createAction<EventActiveAdditionalLayerChanged>(
        'EVENT_ACTIVE_ADDITIONAL_LAYER_CHANGED'
    ),
    chatTotalUnreadMessageCountChanged: createAction<number>(chatTotalUnreadMessageCountChangedType),
};
