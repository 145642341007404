import { handleActions } from 'redux-actions';

import { actions } from '../../../services/actions';
import { clusterSelected } from '../../map/mapSlice';
import { sidebarActions } from '../../sidebar/sidebarActions';
import { chargingStationClusterSelected } from '../../map/mapSlice';
import {
    GeoBookingStates,
    type Address,
    type Asset,
    type CombinedAssetData,
    type Driver,
    type DrivingTimes,
    type Geofence,
    type Group,
    type Pois,
    type TransformedData,
    type GeoBookingState,
} from '../../../services/types';
import { remoteActions } from '../../../features/widgets/remoteActions';

export const defaultDataState = {
    assets: [],
    rawData: null,
    transformedData: [],
    groups: [],
    drivers: [],
    drivingTimes: [],
    addresses: [],
    pois: { workshopPois: [], customerPois: [] },
    geofences: [],
    overallGeoBookingState: GeoBookingStates.inactive,
    fetchInitialDataRequested: false,
    fetchInitialDataFailed: false,
    fetchGroupsRequested: false,
    fetchGroupsFailed: false,
    fetchPoisFailed: false,
    fetchGeofencesRequested: false,
    fetchGeofencesFailed: false,
    fetchAddressesRequested: false,
    fetchAddressesFailed: false,
    activeAssetId: null,
    activePoiId: null,
    activeGeofenceId: null,
    activeChargingStationId: null,
    chatTotalUnreadMessageCount: 0,
};

export type DataState = {
    assets: Asset[];
    rawData: CombinedAssetData[] | null;
    transformedData: TransformedData[];
    groups: Group[];
    drivers: Driver[];
    drivingTimes: DrivingTimes[];
    addresses: Address[];
    pois: Pois;
    geofences: Geofence[];
    overallGeoBookingState: GeoBookingState;
    fetchInitialDataRequested: boolean;
    fetchInitialDataFailed: boolean;
    fetchGroupsRequested: boolean;
    fetchGroupsFailed: boolean;
    fetchPoisFailed: boolean;
    fetchGeofencesRequested: boolean;
    fetchGeofencesFailed: boolean;
    fetchAddressesRequested: boolean;
    fetchAddressesFailed: boolean;
    activeAssetId: string | null;
    activePoiId: string | null;
    activeGeofenceId: string | null;
    activeChargingStationId: string | null;
    chatTotalUnreadMessageCount: number | null;
};

export const dataReducer = handleActions(
    {
        [`${actions.assetsChanged}`]: (state: DataState, action) => ({
            ...state,
            assets: action.payload as Asset[],
        }),
        [`${actions.rawDataChanged}`]: (state: DataState, action) => ({
            ...state,
            rawData: action.payload,
            fetchInitialDataFailed: false,
        }),
        [`${actions.groupsChanged}`]: (state: DataState, action) => ({
            ...state,
            groups: action.payload,
            fetchGroupsFailed: false,
        }),
        [`${actions.driversChanged}`]: (state: DataState, action) => ({ ...state, drivers: action.payload }),
        [`${actions.drivingTimesChanged}`]: (state: DataState, action) => ({ ...state, drivingTimes: action.payload }),
        [`${actions.transformedDataChanged}`]: (state: DataState, action) => ({
            ...state,
            transformedData: action.payload,
        }),
        [`${actions.overallGeoBookingStateChanged}`]: (state: DataState, action) => ({
            ...state,
            overallGeoBookingState: action.payload,
        }),
        [`${actions.activeAssetChanged}`]: (state: DataState, action) => ({
            ...state,
            activeAssetId: action.payload,
            activePoiId: undefined,
            activeGeofenceId: undefined,
            activeChargingStationId: undefined,
        }),
        [`${actions.activePoiChanged}`]: (state: DataState, action) => ({
            ...state,
            activeAssetId: undefined,
            activePoiId: action.payload,
            activeGeofenceId: undefined,
            activeChargingStationId: undefined,
        }),
        [`${actions.activeGeofenceChanged}`]: (state: DataState, action) => ({
            ...state,
            activeAssetId: undefined,
            activePoiId: undefined,
            activeGeofenceId: action.payload,
            activeChargingStationId: undefined,
        }),
        [`${actions.activeChargingStationChanged}`]: (state: DataState, action) => ({
            ...state,
            activeChargingStationId: action.payload,
            activeAssetId: undefined,
            activePoiId: undefined,
            activeGeofenceId: undefined,
        }),
        [clusterSelected]: (state: DataState, action) => {
            if (action.payload) {
                return {
                    ...state,
                    activeAssetId: undefined,
                    activePoiId: undefined,
                    activeGeofenceId: undefined,
                    activeChargingStationId: undefined,
                };
            }
            return state;
        },
        [`${actions.fetchInitialDataRequested}`]: (state: DataState) => ({
            ...state,
            fetchInitialDataRequested: true,
            fetchInitialDataFailed: false,
        }),
        [`${actions.fetchInitialDataFailed}`]: (state: DataState) => ({
            ...state,
            fetchInitialDataFailed: true,
            fetchInitialDataRequested: false,
        }),
        [`${actions.fetchInitialDataSuccess}`]: (state: DataState) => ({
            ...state,
            fetchInitialDataFailed: false,
            fetchInitialDataRequested: false,
        }),

        [`${actions.fetchGroupsRequested}`]: (state: DataState) => ({
            ...state,
            fetchGroupsRequested: true,
            fetchGroupsFailed: false,
        }),
        [`${actions.fetchGroupsFailed}`]: (state: DataState) => ({
            ...state,
            fetchGroupsRequested: false,
            fetchGroupsFailed: true,
        }),
        [`${actions.fetchGroupsSuccess}`]: (state: DataState) => ({
            ...state,
            fetchGroupsRequested: false,
            fetchGroupsFailed: false,
        }),
        [`${actions.fetchPoisFailed}`]: (state: DataState) => ({
            ...state,
            fetchPoisFailed: true,
        }),
        [`${actions.fetchPoisSuccess}`]: (state: DataState, action) => ({
            ...state,
            fetchPoisFailed: false,
            pois: action.payload,
        }),

        [`${actions.fetchGeofencesRequested}`]: (state: DataState) => ({
            ...state,
            fetchGeofencesRequested: true,
            fetchGeofencesFailed: false,
        }),
        [`${actions.fetchGeofencesFailed}`]: (state: DataState) => ({
            ...state,
            fetchGeofencesRequested: false,
            fetchGeofencesFailed: true,
        }),
        [`${actions.fetchGeofencesSuccess}`]: (state: DataState, action) => ({
            ...state,
            fetchGeofencesRequested: false,
            fetchGeofencesFailed: false,
            geofences: action.payload,
        }),

        [`${actions.fetchAddressesRequested}`]: (state: DataState) => ({
            ...state,
            addresses: [],
            fetchAddressesRequested: true,
            fetchAddressesFailed: false,
        }),
        [`${actions.fetchAddressesFailed}`]: (state: DataState) => ({
            ...state,
            fetchAddressesFailed: true,
            fetchAddressesRequested: false,
        }),
        [`${actions.fetchAddressesSuccess}`]: (state: DataState, action) => ({
            ...state,
            addresses: action.payload,
            fetchAddressesFailed: false,
            fetchAddressesRequested: false,
        }),

        [`${sidebarActions.assetSelectedFromClusterSidebar}`]: (state: DataState, action) => ({
            ...state,
            activeAssetId: action.payload,
        }),

        [`${chargingStationClusterSelected}`]: (state: DataState, action) => ({
            ...state,
            activeChargingStationId: undefined,
            activeAssetId: undefined,
            activePoiId: undefined,
            activeGeofenceId: undefined,
        }),

        [`${remoteActions.chatTotalUnreadMessageCountChanged}`]: (state: DataState, action) => ({
            ...state,
            chatTotalUnreadMessageCount: action.payload,
        }),
    },
    defaultDataState
);
