import { lazy, useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import { useIntl } from 'react-intl';
import isEmpty from 'lodash/fp/isEmpty';
import ApplicationLayout from '@rio-cloud/rio-uikit/ApplicationLayout';
import Notification from '@rio-cloud/rio-uikit/Notification';
import NotificationsContainer from '@rio-cloud/rio-uikit/NotificationsContainer';

import { SuspendedWithoutFallback } from '../components/SuspendedWithoutFallback';
import { PoiGeofenceDialogContainer } from '../features/dialogs/poiGeofenceDialog/PoiGeofenceDialog';
import { MessageHandler } from '../features/widgets/MessageHandler';
import ClusterSidebarContainer from '../features/sidebar/cluster/ClusterSidebar';
import { MainContent } from './MainContent';
import { useTimedPageReload } from '../hooks/useTimedPageReload';
import { useSupportMarkers } from '../hooks/useSupportMarkers';
import { useInitialData } from '../hooks/useInitialData';
import { useLocationChange } from '../hooks/useLocationChange';
import { useServiceInfoDialogOnStart } from '../features/serviceInfo/useServiceInfoDialogOnStart';
import { DemoFleetBottomSheet } from '../features/bottomSheets/DemoFleetBottomSheet';
import { MarketplaceSurveyBottomSheet } from '../features/bottomSheets/MarketplaceSurveyBottomSheet';
import { GeoResearchBottomSheet } from '../features/bottomSheets/GeoResearchBottomSheet';
import { MapUpdateFeedbackBottomSheet } from '../features/bottomSheets/MapUpdateFeedbackBottomSheet';
import { OrderCommunicationSurveyBottomSheet } from '../features/bottomSheets/OrderCommunicationSurveyBottomSheet';
import { RouterUpdater } from '../routes/RouteUpdater';
import { MAP_PATH } from '../routes/routes';
import { useChargingStationClusterIds, useIsMapLoaded } from '../features/map/mapHooks';
import {
    useActiveAsset,
    useActiveAssetId,
    useActiveChargingStationId,
    useActiveGeofenceId,
    useActivePoiId,
} from '../features/app/appHooks';

const Header = lazy(() => import('../features/header/Header'));
const AssetTreeContainer = lazy(() => import('../features/tree/AssetTree'));
const AssetSidebar = lazy(() => import('../features/sidebar/asset/AssetSidebar'));
const PoisSidebarContainer = lazy(() => import('../features/sidebar/pois/PoisSidebar'));
const GeofenceSidebarContainer = lazy(() => import('../features/sidebar/geofence/GeofenceSidebar'));
const ChargingStationSidebar = lazy(() => import('../features/sidebar/chargingStations/ChargingStationSidebar'));
const ServiceInfoDialogContainer = lazy(() => import('../features/serviceInfo/ServiceInfoDialog'));
const ShareLinkDialog = lazy(() => import('../features/dialogs/shareLinkDialog/ShareLinkDialog'));
const AssetAdminDialog = lazy(() => import('../features/dialogs/assetAdminDialog/AssetAdminDialog'));

const getOffscreenSidebarClassName = shouldShow => (shouldShow ? '' : 'position-offscreen width-0');

export const AppLayout = () => {
    const intl = useIntl();

    const { data, hasFetchInitialDataFailed, hasFetchGroupsFailed } = useInitialData();

    const activeAssetId = useActiveAssetId();
    const activeAsset = useActiveAsset();
    const activePoiId = useActivePoiId();
    const activeGeofenceId = useActiveGeofenceId();
    const activeChargingStationId = useActiveChargingStationId();
    const chargingStationClusterIds = useChargingStationClusterIds();

    const isMapAvailable = useIsMapLoaded();
    const showMap = useLocation().pathname.startsWith(MAP_PATH);

    useLocationChange();
    useServiceInfoDialogOnStart(showMap);
    useTimedPageReload();
    useSupportMarkers();

    const [hasAssetSidebar, setHasAssetSidebar] = useState(false);
    const [hasPoiSidebar, setHasPoiSidebar] = useState(false);
    const [hasGeofenceSidebar, setHasGeofenceSidebar] = useState(false);
    const [hasChargingStationSidebar, setHasChargingStationSidebar] = useState(false);

    // Don't render poi and geofence sidebar if not needed but keep it in the DOM
    // once they have been loaded
    useEffect(() => {
        activePoiId && !hasPoiSidebar && setHasPoiSidebar(true);
    }, [activePoiId, hasPoiSidebar]);
    useEffect(() => {
        activeGeofenceId && !hasGeofenceSidebar && setHasGeofenceSidebar(true);
    }, [activeGeofenceId, hasGeofenceSidebar]);
    useEffect(() => {
        activeAssetId && !hasAssetSidebar && setHasAssetSidebar(true);
    }, [activeAssetId, hasAssetSidebar]);
    useEffect(() => {
        if (!activeChargingStationId && isEmpty(chargingStationClusterIds)) {
            setHasChargingStationSidebar(false);
        } else {
            (activeChargingStationId || chargingStationClusterIds) &&
                !hasChargingStationSidebar &&
                setHasChargingStationSidebar(true);
        }
    }, [activeChargingStationId, chargingStationClusterIds, hasChargingStationSidebar]);

    // Note: Workaround to show a message about failed fetch when polling data but still showing the table
    if (data && hasFetchInitialDataFailed) {
        Notification.warning(intl.formatMessage({ id: 'intl-msg:livemonitor.fetch.data.failed' }));
    }

    return (
        <ApplicationLayout className={'LiveMonitor'}>
            <ApplicationLayout.Header>
                <SuspendedWithoutFallback>
                    <Header />
                </SuspendedWithoutFallback>
            </ApplicationLayout.Header>
            <ApplicationLayout.Sidebar className={getOffscreenSidebarClassName(showMap)}>
                <SuspendedWithoutFallback>{<AssetTreeContainer />}</SuspendedWithoutFallback>
            </ApplicationLayout.Sidebar>
            <SuspendedWithoutFallback>
                {hasAssetSidebar && isMapAvailable && (
                    <ApplicationLayout.Sidebar
                        className={`AssetSidebar right ${getOffscreenSidebarClassName(activeAsset)}`}
                    >
                        <AssetSidebar />
                    </ApplicationLayout.Sidebar>
                )}
                <ApplicationLayout.Sidebar className={'ClusterSidebarContainer right'}>
                    <ClusterSidebarContainer />
                </ApplicationLayout.Sidebar>
                {activePoiId && isMapAvailable && (
                    <ApplicationLayout.Sidebar
                        className={`PoisSidebarContainer right ${getOffscreenSidebarClassName(activePoiId)}`}
                    >
                        <PoisSidebarContainer />
                    </ApplicationLayout.Sidebar>
                )}
                {activeGeofenceId && isMapAvailable && (
                    <ApplicationLayout.Sidebar
                        className={`GeofenceSidebarContainer right ${getOffscreenSidebarClassName(activeGeofenceId)}`}
                    >
                        <GeofenceSidebarContainer />
                    </ApplicationLayout.Sidebar>
                )}
                {hasChargingStationSidebar && isMapAvailable && (
                    <ApplicationLayout.Sidebar
                        className={`ChargingStationContainer right ${getOffscreenSidebarClassName(
                            hasChargingStationSidebar
                        )}`}
                    >
                        <ChargingStationSidebar />
                    </ApplicationLayout.Sidebar>
                )}
            </SuspendedWithoutFallback>
            <ApplicationLayout.Body innerClassName={`responsive ${showMap ? 'padding-0' : ''}`}>
                <MessageHandler />
                <NotificationsContainer />
                <SuspendedWithoutFallback>
                    <ShareLinkDialog />
                    <ServiceInfoDialogContainer />
                    <PoiGeofenceDialogContainer />
                    <AssetAdminDialog />
                </SuspendedWithoutFallback>
                <MainContent
                    isMap={showMap}
                    data={data}
                    hasFetchInitialDataFailed={hasFetchInitialDataFailed}
                    hasFetchGroupsFailed={hasFetchGroupsFailed}
                />
                {/* <DummyBottomSheet /> */}
                <MarketplaceSurveyBottomSheet />
                {/* <GeoResearchBottomSheet /> */}
                {/* <MapUpdateFeedbackBottomSheet /> */}
                <OrderCommunicationSurveyBottomSheet />
                <DemoFleetBottomSheet />
                <RouterUpdater />
            </ApplicationLayout.Body>
        </ApplicationLayout>
    );
};
